html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    background-image: url(../image/body-dots-min.png);
    font-family: 'Poppins';
}

.body-wrapper {
    overflow: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 72px;
    font-family: 'Lato';
}

h2 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    padding-bottom: 30px;
}

h3 {
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    padding-top: 40px;
    padding-bottom: 20px;
}

p {
    font-size: 17px;
    line-height: 30px;
}

p:not(:last-child) {
    padding-bottom: 25px;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}




/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }




.site-logo {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 9px;
}

.site-logo-svg {
    width: 30px;
}

.logo-wrapper {
    display: flex;
    justify-content: space-between;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    padding: 50px 0 20px;
}

.nav-bar {
    display: flex;
    gap: 100px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
}

.nav-bar li a:hover {
    color: #FF8540;
}


.nav-bar a {
    color: #fff;
}

.site-header {
    background-image: url(../image/hero-dots.png);
    background-size: contain;
    background-repeat: repeat;
    background-color: #0D2333;
    color: #fff;
}

.site-header-inner {
    display: grid;
    grid-template-columns: 55% 1fr;
    padding-top: 95px;
    padding-bottom: 320px;
}

.hero-img {
    position: relative;
}

.hero-img::before {
    position: absolute;
    content: "";
    background-image: url(../image/dices.png);
    top: -50px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 600px;
    height: 630px;
    background-size: contain;
    background-repeat: no-repeat;
}

/* TABLE  */
table {
    width: 100%;
}

table tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 29px;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 10px;
    padding: 8px;
}



.grey {
    background: #F0F4F7;
    border-radius: 10px;
    padding: 26px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000;
    position: relative;
    width: 155px;
    height: 79px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grey::before {
    position: absolute;
    content: "1";
    background-color: #000;
    color: #fff;
    padding: 4px;
    border-radius: 5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.first td:first-of-type .grey::before {
    background-color: #2388FF;
}

table td:nth-of-type(2) .grey::before {
    content: "2";
}

.second td:nth-of-type(1) .grey::before {
    content: "3";
}
.second td:nth-of-type(2) .grey::before {
    content: "4";
}

.third td:nth-of-type(1) .grey::before {
    content: "5";
}

.third td:nth-of-type(2) .grey::before {
    content: "6";
}

.fourth td:nth-of-type(1) .grey::before {
    content: "7";
}

.fourth td:nth-of-type(2) .grey::before {
    content: "8";
}

.fifth td:nth-of-type(1) .grey::before {
    content: "9";
}

.fifth td:nth-of-type(2) .grey::before {
    content: "10";
}

.table {
    padding-top: 50px;
    border-radius: 10px;
    padding-bottom: 50px;
    background: #FDFDFD;
    margin-top: -180px;
    padding-left: 35px;
    padding-right: 35px;
}

.table h2 {
    text-align: center;
    width: 75%;
    margin: 0 auto;
    padding-bottom: 45px;
}



table td a {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 10px;
    color: #FB9206;
    transition: all 0.3s;
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    width: 189px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table table td a:hover {
    background-color: #FB9206;
    color: #fff;
}

.mcap {
    position: relative;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    margin-top: 30px;
}

.mcap::before {
    position: absolute;
    content: "MARKET CAP";
    color: #949494;
    font-size: 16px;
    line-height: 30px;
    top: -30px;
    font-weight: 400;
} 


.types {
    background-color: #1B3345;
    color: #fff;
    border-radius: 10px;
    padding: 60px 70px;
    margin-top: 50px;
}

.types h2 {
    text-align: left;
}

.types p {
    color: #CAD4DB;
}

.types h3 {
    padding-left: 70px;
    position: relative;
}

.types h3:first-of-type::before {
    position: absolute;
    content: "";
    background-image: url(../image/shape1.png);
    width: 51px;
    height: 51px;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
}

#crypto-backed::before {
    position: absolute;
    content: "";
    background-image: url(../image/shape2.png);
    width: 51px;
    height: 51px;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
}
#commodity-backed::before {
    position: absolute;
    content: "";
    background-image: url(../image/shape3.png);
    width: 51px;
    height: 51px;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
}

.swiper-wrapper {
    display: initial;
}

.swiper-pagination {
    display: none;
}

.how {
    padding: 70px 60px;
    background: rgba(253, 253, 253, 0.8);
    border-radius: 10px;
}


/* SECOND TABLE  */

.how table td a {
    width: 170px;
}

.how .grey {
    width: 145px !important;
    color: transparent;
}

.how .grey::after {
    position: absolute;
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
}

.binance .grey::after {
    background-image: url(../image/binance.png);
    width: 100px;
    height: 22px;
}

.coinflex .grey::after {
    background-image: url(../image/coinflex.png);
    width: 100px;
    height: 24px;
}

.okex .grey::after {
    background-image: url(../image/okex.png);
    width: 110px;
    height: 35px;
}

.coinbase .grey::after {
    background-image: url(../image/coinbase.png);
    width: 100px;
    height: 30px;
}

.hitbtc .grey::after {
    background-image: url(../image/hitbtc.png);
    width: 150px;
    height: 30px;
}

.fmfw .grey::after {
    background-image: url(../image/fmfw.png);
    width: 120px;
    height: 25px;
}

.indoex .grey::after {
    background-image: url(../image/indoex.png);
    width: 110px;
    height: 30px;
}
.kucoin .grey::after {
    background-image: url(../image/kucoin.png);
    width: 110px;
    height: 30px;
}

.upbit .grey::after {
    background-image: url(../image/upbit.png);
    width: 100px;
    height: 20px;
}

.changelly .grey::after {
    background-image: url(../image/chalengly-pro.png);
    width: 75px;
    height: 55px;
}

.cta:hover {
    background-color: #FB9206;
    color: #fff;
}


/* FAQ  */

.faq-title h3 {
    font-family: 'Lato';
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    margin-top: 25px;
    margin-bottom: 25px;
    position: relative;
    z-index: 2;
}

.faq {
    position: relative;
    margin: 0 40px;
    padding-bottom: 40px;
}

.faq:not(:last-of-type) {
    border-bottom: 3px solid #F0F4F6;
}


.text-center {
    text-align: center;
    padding-top: 60px;
    position: relative;
}

.faq-btn {
    display: none;
}

.faq h3 {
    position: relative;
    padding-left: 55px;
}

.faq h3:before {
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 42px;
    left: 0;
    position: absolute;
    width: 42px;
    z-index: -1;
}

.faq:nth-of-type(1) h3::before {
    background-image: url(../image/money-bag.png);
}

.faq:nth-of-type(2) h3::before {
    background-image: url(../image/clover.png);
}

.faq:nth-of-type(3) h3::before {
    background-image: url(../image/shield.png);
}

.center{
    text-align: center;
}

.faq-wrapper {
    padding-top: 65px;
    background: rgba(253, 253, 253, 0.8);
    border-radius: 10px;
    margin-top: 30px;
    background: rgba(253, 253, 253, 0.8);
    border-radius: 10px;
}


/* ABOUT US  */

.about-us {
    position: relative;
    z-index: 2;
    padding: 0 25px;
    margin-top: 60px;
 }
 
 .about-us h2 {
     padding-top: 20px;
     color: #fff;
 }

 .about-us p {
     color: #CAD4DB;
 }
 
 .about-us::before {
     position: absolute;
     content: "";
     top: -25px;
     bottom: 25px;
     left: 0;
     right: 0;
     z-index: -1;
     background-color: #1B3345;
     border-radius: 10px;
 }
 
 .cnt-btn {
     padding: 20px;
     width: 450px;
     margin: 0 auto;
     color: #fff;
     font-weight: 700;
     font-size: 18px;
     line-height: 24px;
     background-color: #FB9206;
     box-shadow: 0px 4px 29px rgba(57, 76, 113, 0.2);
     border-radius: 3px;
     padding-top: 30px;
     padding-bottom: 30px;
     cursor: pointer;
     display: flex;
     align-items: center;
     justify-content: center;
     position: relative;
     color: #fff !important;
 }

 
 .about-us p:first-of-type {
     padding-bottom: 45px;
 }

 .slide-down {
    height: 0;
    overflow: hidden;
    transition: height 0.2s linear;
}
.m-slide-down-measure p {
    padding-top: 0px;
    margin: 0 !important;
}
.m-visible {
    display: none;
}
.m-hidden {
    display: initial;
}


/* FOOTER  */

footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #000;
    color: #b1b1b1;
    border-radius: 10px 10px 0 0;
    margin-top: 40px;
}

footer .logo-wrapper a{
    color: #fff !important;
}

footer .logo-wrapper {
    padding: 0;
}

/* MOBILE  */

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 24px;
    }


    .swiper-wrapper {
        display: flex;
    }

    /* Read more  */

    
    .m-slide-down {
        height: 0;
        overflow: hidden;
        -moz-transition: height 0.5s;
        -ms-transition: height 0.5s;
        -o-transition: height 0.5s;
        -webkit-transition: height 0.5s;
        transition: height 0.5s;
      }
      .m-hidden,
      .mobile-detector {
        display: none;
      }
      .m-visible {
        display: initial;
      }
      .m-hidden {
        display: none;
      }
      .m-slide-down-measure p {
        margin: 0 !important;
        padding-top: 20px;
      }
      .m-readmore-btn {
        background: transparent;
        border: none;
        font-size: 20px;
        font-weight: 500;
        color: #FB9206;
        width: 100%;
        margin: 15px auto 50px;
        text-decoration: underline;
      }
      
      .m-readmore-btn::before {
        cursor: pointer;
        display: inline-block;
        width: 25px;
        height: 25px;
        vertical-align: top;
        margin-left: 5px;
        color: rgb(22, 22, 22);
        font-size: 24px !important;
      }
      
      
      .m-readmore-btn * {
        pointer-events: none;
      }
      .m-visible {
        display: initial;
      }
    

    .swiper-pagination {
        display: block;
    }


    .site-header-inner {
        grid-template-columns: 1fr;
        padding-bottom: 127px;
        padding-top: 30px;
    }

    .table {
        margin-top: 120px;
    }

    .hero-img::before {
        width: 400px;
        height: 400px;
        top: -100px;
        right: -65px;
        left: auto;
    }

    body {
        background-image: none;
    }

    .types {
        background-color: #fff;
    }

    .types h2 {
        color: #000;
        text-align: center;
    }

    

    .type-mobile {
        color: #1C303F !important;
        text-align: center;
    }
    .swiper-slide {
        background-color: #1B3345 !important;
        text-align: center;
    }
    
    .types h3 {
        padding-left: 0;
        padding-top: 130px;
    }

    .types h3::before {
        width: 100px !important;
        height: 100px !important;
        left: calc(50% - 50px) !important;
        top: 0;
    }

    .how {
        padding: 0;
    }

    .faq {
        margin: 0 0 20px;
    }

    .how .grey {
        background-color: #dfe7ed;
    }

    .content {
        position: relative;
        z-index: 2;
    }

    .table {
        padding-left: 0;
        padding-right: 0;
    }
}


@media only screen and (max-width: 1200px) {
    table tr {
        grid-template-columns: 1fr;
    }
    .nav-bar {
        gap: 70px;
    }

    .cards {
        gap: 30px;
    }

    .what-is {
        grid-template-rows: 440px 1fr;
        grid-template-columns: 1fr;
        padding: 10px;
    }

    .what-is::after {
        z-index: 2;
        left: -45px;
    }

    .what-is::before {
        display: none;
    }

    .table h2 {
        width: 100%;
        padding-bottom: 50px;
    }
}

@media only screen and (max-width: 1000px) {
    
    .nav-bar {
        gap: 30px;
    }

    .cards {
        flex-direction: column;
    }
    
    .swiper-slide {
        width: 100%;
    }
}

/* MOBILE  */

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 24px;
    }

    h2 {
        font-weight: 800;
        font-size: 32px;
        line-height: 38px;
        padding-bottom: 15px;
    }

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
    }

    .container {
        padding: 0 20px;
    }

    .nav-bar {
        display: none;
    }

    

    .what-is {
        grid-template-columns: 1fr;
        grid-template-rows: 310px 1fr;
        padding: 10px;
    }

    .what-is::after {
        left: -60px;
        z-index: 3;
    }

    .what-is-content {
        padding: 20px 25px;
    }

    .what-is-content h2 {
        text-align: left;
    }

    .what-is::before,
    .site-header-inner p::before,
    h1::before {
        display: none
    }

    .site-header .container::after {
        right: -50px;
        top: 350px;
    }

    .site-header .container::before {
        background-repeat: no-repeat;
        width: 140px;
        height: 120px;
        top: 90px;
        left: -40px;
    }


    .purpose h2 {
        padding-top: 40px;
    }

    .padding-bottom {
        padding-bottom: 35px;
    }

    .types {
        margin-top: 33px;
        padding: 35px 15px;
    }

    

    /* SWIPER  */

    .cards {
        display: none;
    }
    .swiper {
        display: block;
        overflow: visible;
    }

    .swiper-wrapper {
        box-sizing: border-box !important;
        display: flex;
    }

    .swiper-slide {
        background-color: #fff;
        box-shadow: 0px 4px 105px rgb(34 48 74 / 20%);
        border-radius: 10px;
        padding: 35px 25px 40px;
        position: relative;
    }

    .swiper-slide::before {
        right: 208px
    }

    .swiper-pagination-bullet-active {
        background-color: #2388FF;
    }

    .swiper-pagination-bullet {
        color: transparent;
    }
    .swiper-pagination {
        position: initial;
        padding-top: 9px;
       display: block;
    }

    #features {
        margin-bottom: 20px;
        text-align: center;
    }
    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 10px;
    }

    /* TABLE  */

    table tr {
        grid-template-columns: 1fr;
    }

    .mcap {
        order: 3;
        grid-column-end: span 2;
        display: flex;
        justify-content: center;
    }

    .flex {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    .grey,
    table td a {
        width: 100%;
    }

    table td a {
        background-color: #FB9206;
        color: #fff;
    }

    .table {
        padding-top: 45px;
        padding-bottom: 10px;
    }

    .table h2 {
        width: 100%;
        padding-bottom: 30px;
    }

    .how .grey {
        width: 100% !important;
    }

    .how table td a {
        width: 100% !important;
    }







    /* FAQ  */

    .text-center h2{
        color: #000;
        font-weight: 300;
      }

    .faq {
        background: #FFFFFF;
        box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
        border-radius: 10px;
        margin-bottom: 20px;
      }
      
      .faq-wrapper{
        position: relative;
        z-index: 2;
      }
      .faq-section-wrapper{
        padding-bottom: 90px;
      }
       /* .faq-heading {
        margin-bottom: 38px!important;
        padding-top: 68px!important
      } 
       */
       .faq-wrapper {
        padding-top: 26px;
        }
        .center {
            padding-bottom: 25px;
        }
      
      .faq.expanded+.faq {
        border-top: 0
      }
      
      
      .faq-title {
        align-items: center;
        display: flex;
        justify-content: space-between
      }
      
      .faq-title h3 {
        color: #000;
        font-size: 28px;
        font-weight: 400;
        height: 98px;
        line-height: 98px;
        pointer-events: none;
        margin-bottom: 0 !important;
        margin-top: 0;
      }
      .faq-btn {
        align-items: center;
        border: 0;
        border-radius: 25px;
        display: flex;
        font-size: 42px;
        height: 49px;
        justify-content: center;
        width: 49px
      }
      
      
      
      .faq-btn:hover,.faq-title:hover {
        cursor: pointer
      }
      
      .faq {
        
        transition: background-color .2s linear,font-weight .2s linear,border-radius .2s linear;
        position: relative;
        padding-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
        
      }
      .faq-background-outer{
        border: 1px solid #3B495F;
        position: absolute;
        border-radius: 40px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
      .faq-content{
        position: relative;
        z-index: 2;
      }
      
      .faq .faq-body {
        color: #3B495F;
        font-size: 17px;
        line-height: 24px;
        padding-bottom: 44px;
        border-top: 1px solid #3B495F;
        padding-top: 25px;
        width: 95%;
      }
      .faq.collapsed .faq-body {
        display: none
      }
      .faq-body a{
        font-weight: bold;
        color: #000;
        text-decoration: underline;
      }
      
      
      
      .faq-btn span {
        pointer-events: none
      }
      
      .faq.collapsed .faq-btn span {
        color: #000
      }
      
      .faq.expanded .faq-btn span {
        color: #000
      }
      
      .faq.collapsed .faq-btn span.plus,.faq.expanded .faq-btn span.minus {
        display: block
      }
      
      .faq.collapsed .faq-btn span.minus,.faq.expanded .faq-btn span.plus {
        display: none
      }
      .faq.expanded .faq-title h3 {
        font-weight: 700
      }
      .text-center {
        text-align: center;
        color: #000;
      }
      .text-center p{
        padding-bottom: 50px;
        color: #000;
      }
      .faq-title h3{
        font-size: 21px;
        font-weight: 300;
        line-height: 20px;
        display: flex;
        align-items: center;
      }
      .faq-body p{
        color: #000;
        text-align: left;
      }   

      .about-us::before {
          bottom: 70px;
          top: -15px;
      }

      .cnt-btn {
          width: 222px;
      }

      footer .container {
        flex-direction: column;
        margin-top: 20px;
        gap: 8px;
      }
}